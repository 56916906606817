/* client/src/components/TheatreMode.js */
import React from 'react';
import './TheatreMode.css';

const TheatreMode = ({ image, onBack, onNext, onPrevious }) => {
  return (
    <div className="theatre-mode">
            <div className="image-container">
        <img src={image.full} alt="Selected" />
      </div>
      <div className="button-container">
        <button className="back-button" onClick={onBack}>
          &larr; Back to Grid
        </button>
        <div className="navigation-buttons">
          <button className="previous-button" onClick={onPrevious}>
            &larr; Previous
          </button>
          <button className="next-button" onClick={onNext}>
            Next &rarr;
          </button>
        </div>
      </div>
    </div>
  );
};

export default TheatreMode;
