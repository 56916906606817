/* client/src/App.js */
import React, { useState } from 'react';

import Sidebar from './components/Sidebar'; //Import Sidebar
import PhotoGrid from './components/PhotoGrid'; //Import Photogrid
import TheatreMode from './components/TheatreMode'; // Import ImageViewer
import './App.css';

// Import the image data from PhotoGrid or define it here if you want it global
import { imageData } from './components/PhotoGrid'; // Make sure to export imageData from PhotoGrid

function App() {
  // State to keep track of the current category
  const [currentCategory, setCurrentCategory] = useState('All');
  const [selectedImageIndex, setSelectedImageIndex] = useState(null); // Track the selected image index


  // Function to handle category change from the sidebar
  const handleCategoryChange = (category, imageData) => {
    setCurrentCategory(category);
    setSelectedImageIndex(null); // Reset Theatre Mode when changing categories
    // Update the images based on category
  };

  // Function to handle image click and open Theatre Mode
  // Handle image click and open Theatre Mode
  const handleImageClick = (imageIndex) => {
    setSelectedImageIndex(imageIndex);
  };

  // Function to go back to grid mode
  const handleBackToGrid = () => {
    setSelectedImageIndex(null);
  };

  // Function to go to the next image
  const handleNextImage = () => {
    const imagesToDisplay = imageData[currentCategory] || imageData['All'];
    if (selectedImageIndex !== null && selectedImageIndex < imagesToDisplay.length - 1) {
      setSelectedImageIndex(selectedImageIndex + 1);
    }
  };

  // Function to go to the previous image
  const handlePreviousImage = () => {
    if (selectedImageIndex !== null && selectedImageIndex > 0) {
      setSelectedImageIndex(selectedImageIndex - 1);
    }
  };

  // Get images for the selected category
  const imagesToDisplay = imageData[currentCategory] || imageData['All'];

  return (
    <div className="App">
      <div className="sidebar">
        <Sidebar onCategoryChange={handleCategoryChange} />
      </div>

      <div className="content">
        {selectedImageIndex !== null ? (
          // Show TheatreMode if an image is selected
          <TheatreMode
            image={imagesToDisplay[selectedImageIndex]}
            onBack={handleBackToGrid}
            onNext={handleNextImage}
            onPrevious={handlePreviousImage}
          />
        ) : (
          // Show PhotoGrid if no image is selected
          <PhotoGrid
            category={currentCategory}
            onImageClick={handleImageClick}
          />
        )}
      </div>
    </div>
  );
}

export default App;