/* client/src/components/Sidebar.js */
import React, { useState } from 'react'; // Import React

import './Sidebar.css'; // Import CSS

const Sidebar = ({ onCategoryChange }) => {
  const [portfolioOpen, setPortfolioOpen] = useState(false);

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setPortfolioOpen(!portfolioOpen);
  };

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <h4>Temporary Window</h4>
      </div>
      <ul className="sidebar-menu">
        <li onClick={() => onCategoryChange('All')}>Photos</li>
        {/* 
        <li>
          <span onClick={toggleDropdown} className="portfolio-toggle">
            Portfolio
          </span>
          
          <ul className={`dropdown ${portfolioOpen ? 'show' : ''}`}>
            <li onClick={() => onCategoryChange('Aviation')}>Aviation</li>
            <li onClick={() => onCategoryChange('Wildlife')}>Wildlife</li>
            <li onClick={() => onCategoryChange('Street')}>Street</li>
          </ul>
          
        </li>
        */}
        <li onClick={() => onCategoryChange('About')}>About</li>
      </ul>
    </div>
  );
};

export default Sidebar;
