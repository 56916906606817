/* client/src/components/PhotoGrid.js */
import React from 'react';
import './PhotoGrid.css';

const images = [];
const full_images = [];
images.push('/images/thumbnails/KLM_TH.jpg');
full_images.push('/images/fullimages/KLM.jpg');
images.push('/images/thumbnails/LH747_TH.jpg');
full_images.push('/images/fullimages/LH747.jpg');
images.push('/images/thumbnails/AC777YVR_TH.jpg');
full_images.push('/images/fullimages/AC777YVR.jpg');
images.push('/images/thumbnails/AC787YYZ_TH.jpg');
full_images.push('/images/fullimages/AC787YYZ.jpg');
images.push('/images/thumbnails/AC787HOUSE_TH.jpg');
full_images.push('/images/fullimages/AC787HOUSE.jpg');


// Sample image data with paths for demonstration
export const imageData = {
    All: [
      { thumbnail: images[0], full: full_images[0], category: 'Aviation' },
      { thumbnail: images[1], full: full_images[1], category: 'Aviation' },
      { thumbnail: images[2], full: full_images[2], category: 'Aviation' },
      { thumbnail: images[3], full: full_images[3], category: 'Aviation' },
      { thumbnail: images[4], full: full_images[4], category: 'Aviation' },
    ],
    Aviation: [
    ],
    Wildlife: [
  
    ],
    Street: [
  
    ],
  };

  const PhotoGrid = ({ category, onImageClick }) => {
    // Get images for the selected category or all if 'All' is selected
    const imagesToDisplay = imageData[category] || imageData['All'];
  
    return (
      <div className="grid-container">
        {imagesToDisplay.map((image, index) => (
          <div
            key={index}
            className="grid-item"
            onClick={() => onImageClick(index)} // Pass the image index on click
          >
            <img src={image.thumbnail} alt={`Category ${category} Image ${index + 1}`} />
          </div>
        ))}
      </div>
    );
  };
  
  export default PhotoGrid;


